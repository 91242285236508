<template>
  <div>
    <b-overlay
      :show="loadingInit"
      rounded
      opacity="0.8"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block w-100"
    >
      <modal-confirm-action
        variant='danger'
        refModal='removeList'
        idModal='removeList'
        :loading='loading'
        :description='descriptionRemove'
        :title='titleRemove'
        @confirmed='deletePromotionalCodes'
      />

      <modal-confirm-action
        variant='danger'
        refModal='removeCoupon'
        idModal='removeCoupon'
        :loading='loading'
        :description='descriptionRemove'
        :title='titleRemove'
        @confirmed='remove'
      />

      <b-modal
        ref='promotion'
        size='lg'
        scrollable hide-footer
        title='Adicionar usuário na lista de Promoções de Renovação'
        header-bg-variant='info'
        header-text-variant='light'
      >
        <div class='modal-list-create-promocode modal-font-family'>
          <div class='mb-3'>
            <label class='label'>
              E-mail
            </label>
            <b-form-input
              v-model='userPromotionalCode.client'
              label='Email do usuário'
              :state='validEmail'
              class='form-coupon'
              placeholder='Digite o email do usuário'>
            </b-form-input>

            <b-form-invalid-feedback :state='validEmail'>
              Este campo precisa ser um e-mail valido
            </b-form-invalid-feedback>
          </div>

          <div class='d-flex'>
            <base-button
              class='mr-1 w-50'
              block
              danger
              @click='closePromotion()'
            >
              Cancelar
            </base-button>

            <b-overlay
              :show="addedUser"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block w-50"
            >
              <base-button
                @click='addUserPromotionalCodes()'
                success
                :disabled='!validEmail'
                block
              >
                <span>Adicionar</span>
              </base-button>
            </b-overlay>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref='renewalPromotions'
        size='lg'
        scrollable
        hide-footer
        title='Cadastrar lista de Promoções de Renovação'
        header-bg-variant='info'
        header-text-variant='light'
      >
        <div class='modal-list-create-promocode modal-font-family'>
          <div class='mb-3'>
            <label class='label'>
              Descrição
            </label>
            <b-form-input
              v-model='v$.promotionalCode.description.$model'
              label='Descrição'
              class='form-coupon'
              placeholder='Digite a descrição'
            >
            </b-form-input>
          </div>

          <div class='mb-3'>
            <label class='label'>Desconto</label>
            <b-form-input
              id='discount'
              class='form-control form-coupon mb-2'
              type='number'
              placeholder='10'
              v-model='v$.promotionalCode.discount.$model'>
            </b-form-input>
          </div>

          <div class='d-flex'>
            <base-button
              class='mr-1 w-50'
              danger
              @click='closeRenewalPromotions()'
            >
              Cancelar
            </base-button>

            <b-overlay
              :show="addedList"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block w-50"
              @hidden="onHidden"
            >
              <base-button
                success
                @click='submitPromotionalCodes()'
                :disabled='v$.$invalid'
                block
              >
                {{ promotionalCode._id ? 'Atualizar' : 'Cadastrar' }}
              </base-button>
            </b-overlay>
          </div>
        </div>
      </b-modal>

      <b-card no-body>
        <b-tabs pills card class='promotional-codes'>
          <b-tab @click="setBreadcrumbTabsName('Lista de desconto de renovação')" title='Lista de desconto de renovação'>
            <div class='accordion' role='tablist'>
              <div class='w-100 d-flex justify-content-end mb-3'>
                <b-button variant='primary' class='btn-register' @click='showRenewalPromotions(null, $event)'>
                  Cadastrar
                </b-button>
              </div>

              <b-card no-body class='mb-1'>
                <div v-for='code in promotionalCodes' :key='code._id' class='mb-1'>
                  <b-list-group-item
                    block
                    v-b-toggle='`${code._id}`'
                    active
                  >
                    <div class='d-flex text-left align-items-center justify-content-between'>
                      <div>
                        <strong>Descrição: </strong> {{ code.description }}
                        <strong>Desconto: </strong>{{ code.discount }}%
                      </div>

                      <div>
                        <strong>Quantidade: </strong> {{ code.promoCode.length }}

                        <b-button
                          class='mr-1'
                          variant='link'
                          @click='showPromotion(code._id, $event)'
                        >
                          <b style='color: #FFFFFF'>Adicionar usuário na lista</b>

                        </b-button>

                        <b-button
                          class='mr-1'
                          variant='link'
                          @click='showRenewalPromotions(code, $event)'
                        >
                          <b style='color: #FFFFFF'>Editar</b>
                        </b-button>

                        <b-button
                          class='mr-1'
                          variant='link'
                          @click='showDialogConfurmationsDetelePromotionalCodes(code, $event)'
                        >
                          <b style='color: #FFFFFF'>Excluir</b>
                        </b-button>
                      </div>
                    </div>
                  </b-list-group-item>

                  <b-collapse class='mb-1' :id='code._id' :accordion='code._id' :role='code._id'>
                    <b-card-body>
                      <b-list-group>
                        <b-list-group-item
                          class='d-flex justify-content-between align-items-center'
                          v-for='promo in code.promoCode' :key='promo._id'
                        >
                          <div>
                            <strong>Email: </strong>{{ promo.client }}
                            <strong>Desconto: </strong>{{ promo.discount }}%
                          </div>
                          <base-button @click='showRemoveEmailmodal(promo)'>Remover</base-button>
                        </b-list-group-item>
                      </b-list-group>
                    </b-card-body>
                  </b-collapse>
                </div>
              </b-card>
            </div>
          </b-tab>

          <b-tab @click="setBreadcrumbTabsName('Desconto de renovação')" title='Desconto de renovação'>
            <div class='create-promocode'>
              <!--<h4>Desconto de renovação</h4>-->
              <label class='label'>
                Email do usuário
              </label>
              <b-form-input
                v-model='promocode.client'
                label='Email do usuário'
                class='form-coupon'
                placeholder='Digite o email do usuário'
                :error='errors.client'
              ></b-form-input>


              <label class='label mt-2'>
                Desconto
              </label>
              <b-form-input
                id='discount'
                class='form-coupon'
                type='number'
                :state='max'
                placeholder='10'
                v-model='promocode.discount'>
              </b-form-input>

              <b-form-invalid-feedback :state='max' id='discount'>
                O desconto tem que ser mair que 0 e menor que 100!
              </b-form-invalid-feedback>

              <section class='w-100'>
                <b-button
                  @click='handleSubmit'
                  :disabled='!isValid()'
                  variant='success'
                  class='w-25 float-right'
                >
                  Adicionar
                </b-button>
              </section>

              <hr/>
              <h5 v-if='renews.length > 0'>Descontos vigentes:</h5>

              <b-list-group>
                <b-list-group-item class='d-flex justify-content-between align-items-center' v-for='promo in renews' :key='promo._id'>
                  <div>
                    <strong>Email: </strong>{{ promo.client }} <b>Desconto: </b>{{ promo.discount }}%
                  </div>
                  <base-button @click='showRemoveEmailmodal(promo)'>Remover</base-button>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>

      <template v-slot:overlay>
        <div class="text-center loading">
          <b-spinner style="width: 2.5rem; height: 2.5rem;" variant='primary' label="Large Spinner"></b-spinner>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { between } from '@vuelidate/validators'
import { required } from '@/utils/validators'
import DialogConfirmation from '@/components/DialogConfirmation.vue'
import ModalConfirmAction from '@/components/ModalConfirmAction.vue'

export default {
  name: 'CreatePromocode',
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    DialogConfirmation,
    ModalConfirmAction,
  },
  data() {
    return {
      breadcrumbTabsName: 'Lista de desconto de renovação',
      loading: false,
      descriptionRemove: '',
      titleRemove: '',
      idCouponRemove: null,
      addedUser: false,
      addedList: false,
      deletePromotionalCodesId: null,
      openDialog: false,
      loadingInit: false,
      userPromotionalCode: {
        client: null,
        promotionalCodesId: ''
      },
      errors: {
        client: '',
        description: ''
      },
    }
  },
  async created() {
    this.loadingInit = true
    await this.getRenew()
    await this.init()
    this.setBreadcrumbTabsName('Lista de desconto de renovação')
    this.loadingInit = false
  },
  validations() {
    return {
      promotionalCode: {
        description: { required },
        discount: { required, betweenValue: between(1, 100) }
      }
    }
  },
  computed: {
    ...mapGetters('promocodes', {
      promocode: 'promocode',
      promotionalCode: 'promotionalCode',
      renews: 'renews',
      promotionalCodes: 'promotionalCodes'
    }),


    validEmail() {
      const regexIsEmail = /\S+@\S+\.\S+/
      return this.userPromotionalCode.client ? !!(regexIsEmail.test(this.userPromotionalCode.client) && this.userPromotionalCode.client) : null
    },
    max() {
      return !!(Number(this.promocode.discount) < 100 && this.promocode.discount)
    }
  },
  methods: {
    ...mapActions('promocodes', {
      handleCreatePromocode: 'handleCreatePromocode',
      handleCreatePromotionalCodes: 'handleCreatePromotionalCodes',
      removePromocode: 'removePromocode',
      handleClearPromocode: 'handleClearPromocode',
      getRenew: 'getRenew',
      getAllPromotionalCodes: 'getAllPromotionalCodes',
      setListPromocode: 'setListPromocode',
      resetListPromocode: 'resetListPromocode',
      handleUpdatePromotionalCodes: 'handleUpdatePromotionalCodes',
      handleAddUserPromotionalCodes: 'handleAddUserPromotionalCodes',
      handleGetPromotionalCodes: 'handleGetPromotionalCodes',
      handleDeletePromotionalCodes: 'handleDeletePromotionalCodes'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    setBreadcrumbTabsName(breadcrumbTabsName){
      this.breadcrumbTabsName = breadcrumbTabsName;
      const breadcrumb = {
        page: 'Relatórios',
        subPage: {
          name: 'Relatórios',
          active: false
        },
        tabs: [
          {
            name: this.breadcrumbTabsName,
            active: true
          }
        ]
      }
      this.setBreadcrumb(breadcrumb)
    },
    async init() {
      this.loadingInit = true
      await this.getAllPromotionalCodes()
      this.loadingInit = false
    },

    onHidden() {
      this.$refs.button.focus()
    },

    isValid() {
      const regexIsEmail = /\S+@\S+\.\S+/
      const isEmail = regexIsEmail.test(this.promocode.client)

      if (!isEmail) {
        if (this.promocode.client) {
          this.errors.client = 'Este campo precisa ser um email'
        }

        return false
      }
      if (!this.max) {
        return false
      }

      this.errors.client = ''
      return true
    },

    async handleSubmit() {
      const response = await this.handleCreatePromocode()

      let message = ''
      let variant = ''

      if (response.message) {
        message = response.message
        variant = 'success'
        await this.handleClearPromocode()
      }
      if (response.errors) {
        message = response.errors
        variant = 'danger'
      }

      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      this.loadingInit = true
      await this.getRenew()
      this.loadingInit = false
    },
    showRemoveEmailmodal(coupon){
      this.descriptionRemove = `Tem certeza que deseja excluir o cupom de desconto do e-mail: ${coupon.client} ?`
      this.titleRemove = `Excluir cupom de desconto`
      this.idCouponRemove = coupon._id
      this.$bvModal.show('removeCoupon')
    },

    async remove() {
      try {
        this.loading = true;
        const response = await this.removePromocode(this.idCouponRemove)

        let message = ''
        let variant = ''

        if (response.message) {
          message = response.message
          variant = 'success'
          await this.handleClearPromocode()
        }
        if (response.errors) {
          message = response.errors
          variant = 'danger'
        }

        this.$bvToast.toast(message, {
          title: 'Aviso',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.loadingInit = true
        await this.getRenew()
        await this.init()
        this.loading = false;
        this.loadingInit = false
        this.$bvModal.hide('removeCoupon')
      } catch (e){

      }
    },

    showRenewalPromotions(list, event) {
      event.stopPropagation()
      this.resetListPromocode()
      if (list) {
        this.setListPromocode(list)
        this.handleGetPromotionalCodes(list._id)
      }
      this.$refs['renewalPromotions'].show()
    },

    closeRenewalPromotions() {
      this.$refs['renewalPromotions'].hide()
    },

    showPromotion(promotionalCodesId, event) {
      event.stopPropagation()
      this.userPromotionalCode.client = null
      this.userPromotionalCode.promotionalCodesId = promotionalCodesId
      this.$refs['promotion'].show()
    },

    closePromotion() {
      this.$refs['promotion'].hide()
    },

    async submitPromotionalCodes() {
      try {
        this.addedList = true;
        let response = ''
        if (this.promotionalCode._id) {
          response = await this.handleUpdatePromotionalCodes(this.promotionalCode._id)
        } else {
          response = await this.handleCreatePromotionalCodes()
        }
        let message = ''
        let variant = ''
        if (response.message) {
          message = response.message
          variant = 'success'
          await this.handleClearPromocode()
        }
        if (response.errors) {
          message = response.errors
          variant = 'danger'
        }

        this.$bvToast.toast(message, {
          title: 'Aviso',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.closeRenewalPromotions()
        await this.init()
      } catch (e) {

      } finally {
        this.addedList = false;
      }
    },

    async addUserPromotionalCodes() {
      try {
        this.addedUser = true;
        const response = await this.handleAddUserPromotionalCodes(this.userPromotionalCode)

        let message = ''
        let variant = ''
        if (response.message) {
          message = response.message
          variant = 'success'
          await this.handleClearPromocode()
        }
        if (response.errors) {
          message = response.errors
          variant = 'danger'
        }

        this.$bvToast.toast(message, {
          title: 'Aviso',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.closeRenewalPromotions()
        await this.init()
        this.closePromotion()
        await this.init()
      } catch (e) {

      } finally {
        this.addedUser = false;
      }
    },

    async deletePromotionalCodes() {
      try {
        this.loading = true
        await this.handleDeletePromotionalCodes(this.deletePromotionalCodesId)
        await this.init()
        this.$bvModal.hide('removeList')
        this.loading = false
      } catch (e) {

      }
    },

    showDialogConfurmationsDetelePromotionalCodes(promotionalCodes, event) {
      event.stopPropagation()
      console.log(promotionalCodes)
      this.deletePromotionalCodesId = promotionalCodes._id
      this.descriptionRemove = `Esta ação vai exluir a lista com os cupons de desconto e os cupons de desconto da lista: ${promotionalCodes.description}. Deseja realmente excutar essa ação?`
      this.titleRemove = `Excluir lista de cupons de desconto`
      this.$bvModal.show('removeList')
    }
  }

}
</script>

<style lang='scss' scoped>
.modal-list-create-promocode {

}

.promotional-codes {
  .btn-register {
    border-radius: 4px;
    font-weight: bold;
  }
}

.create-promocode {
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    display: inline-block;
  }

  h4 {
    font-weight: bold;
    border-bottom: 2px solid var(--blue);
    color: var(--blue);
    padding-bottom: 4px;
  }
}
.form-coupon{
  height: 48px;
}
.label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: -6px;

}
</style>
