<template>
  <div class="promocode-view mt-2">
    <create-promocode />
  </div>
</template>

<script>
import CreatePromocode from '@/components/Promocodes/CreatePromocode.vue'

export default {
  name: 'PromocodeView',
  components: {
    CreatePromocode
  }
}
</script>

<style>

</style>
